/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import {toAbsoluteUrl} from '../../../helpers'

type Props = {
  className?: string
  bgColor?: string
  title?: string
  title2?: string
  data: RouteChangelogData[]
  loading: boolean
}

type RouteChangelogData = {
  date: string
  schedules: any[]
}
const TilesWidget2 = ({
  className,
  bgColor = '#1C3C96',
  title = 'Flight Schedule Updates',
  title2 = '',
  data,
  loading = true,
}: Props) => {
  if (loading) {
    return (
      <div
        className={clsx('card h-175px bgi-no-repeat bgi-size-contain', className)}
        style={{
          backgroundColor: bgColor,
          backgroundPosition: 'right',
          backgroundImage: `url("${toAbsoluteUrl('/media/misc/schedules-virtualua-pattern.png')}")`,
        }}
      >
        <div className='card-body d-flex flex-column justify-content-between'>
          <h2 className='text-white fw-bold '>
            {/* {title} <br /> {title2}{' '} */}
            {title}
          </h2>
          <div className='fw-semibold fs-7 mb-5'>Loading...</div>

          <div className='m-0'>
            <a
              href='/route-changelog'
              className='btn btn-outline fw-semibold px-6 py-3 border-white'
            >
              See Changelog
            </a>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div
      className={clsx('card h-175px bgi-no-repeat bgi-size-contain', className)}
      style={{
        backgroundColor: bgColor,
        backgroundPosition: 'right',
        backgroundImage: `url("${toAbsoluteUrl('/media/misc/schedules-virtualua-pattern.png')}")`,
      }}
    >
      <div className='card-body d-flex flex-column justify-content-between'>
        <h2 className='text-white fw-bold mb-0'>
          {/* {title} <br /> {title2}{' '} */}
          {title}
        </h2>
        <div className='fw-semibold fs-7 mb-5'>Last Updated {data[0] ? data[0].date : ''}</div>

        <div className='m-0'>
          <a href='/route-changelog' className='btn btn-outline fw-semibold px-6 py-3 border-white'>
            See Changelog
          </a>
        </div>
      </div>
    </div>
  )
}

export {TilesWidget2}
