/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useEffect, useState} from 'react'
import {useQuery} from 'react-query'
import {createBid, getSchedules} from '../../../../middleware'
import {KTSVG} from '../../../helpers'

import moment from 'moment'
import {useFormik} from 'formik'
import * as Yup from 'yup'
import {CARRIER_ID} from '../../../../config'
import {useLocation, useParams} from 'react-router-dom'

type Props = {
  className: string
  depIcao?: string
  arrIcao?: string
  onChange?: (e: any) => void
}

type ScheduleData = {
  public_id: string
  active: boolean
  aircraft_type: string
  altitude: number
  arr_icao: string
  dep_icao: string
  dep_gate?: string
  arr_gate?: string
  callsign: string
  callsign_2?: string
  flight_number: number
  flight_number_2?: number
  est_flight_time: number
  notes: string
  route: string
  updated_at: string
  // For form
  added?: boolean
}

interface IAddSchedule {
  public_id: string
}

const addSchedule = {
  public_id: '',
}

const Alert: React.FC<{error: boolean; msg: string}> = ({error, msg}) => {
  if (!msg) return <></>
  if (error) {
    return <div className='absolute top-0 badge badge-light-danger p-12'>{msg}</div>
  }
  return <div className='badge badge-light-success p-12'>{msg}</div>
}

const searchSchedulesSchema = Yup.object().shape({
  carrierId: Yup.string(),
  dep: Yup.string(),
  arr: Yup.string(),
  aircraftType: Yup.string(),
  flightTimeGt: Yup.number(),
  flightTimeLt: Yup.number(),
})

export interface ISearchSchedules {
  carrierId: string
  dep?: string
  arr?: string
  aircraftType?: string
  flightTimeGt?: number
  flightTimeLt?: number
}

export const searchSchedulesInitial: ISearchSchedules = {
  carrierId: CARRIER_ID,
}

const SearchForm: React.FC<{formik: any; types: string[]}> = ({formik, types}) => {
  return (
    <form className='' onSubmit={formik.handleSubmit}>
      <div className='d-flex align-items-center flex-row flex-wrap'>
        <div className='border-bottom py-2 w-125px'>
          <input
            className='form-control border-0 p-0 me-5 resize-none min-h-25px border border-gray-300 border-solid'
            type='text'
            placeholder='Departure ICAO'
            {...formik.getFieldProps('dep')}
          />
        </div>
        <div className='border-bottom py-2 w-125px'>
          <input
            className='form-control border-0 p-0 me-5 resize-none min-h-25px'
            type='text'
            placeholder='Arrival ICAO'
            {...formik.getFieldProps('arr')}
          />
        </div>

        <div className='w-150px me-5 py-2'>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-white form-select-sm'
            {...formik.getFieldProps('aircraftType')}
          >
            <option value={''} selected={true}>
              Aircraft Type
            </option>
            {types.map((type: string) => {
              return (
                <option value={type} key={'type-' + type}>
                  {type}
                </option>
              )
            })}
          </select>
        </div>
        <div className='w-150px me-5 py-2'>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-white form-select-sm'
            {...formik.getFieldProps('flightTimeGt')}
          >
            <option value={''} selected={true}>
              Minimum Flight Time
            </option>
            <option value={0}> 0 Hours </option>
            <option value={1}> 1 Hour </option>
            <option value={2}> 2 Hours </option>
            <option value={3}> 3 Hours </option>
            <option value={4}> 4 Hours </option>
            <option value={5}> 5 Hours </option>
            <option value={6}> 6 Hours </option>
            <option value={7}> 7 Hours </option>
            <option value={8}> 8 Hours </option>
            <option value={9}> 9 Hours </option>
          </select>
        </div>
        <div className='w-150px me-5 py-2'>
          <select
            name='status'
            data-control='select2'
            data-hide-search='true'
            className='form-select form-select-white form-select-sm'
            {...formik.getFieldProps('flightTimeLt')}
          >
            <option value={1} selected={true}>
              Maximum Flight Time
            </option>
            <option value={1}> 1 Hour </option>
            <option value={2}> 2 Hours </option>
            <option value={3}> 3 Hours </option>
            <option value={4}> 4 Hours </option>
            <option value={5}> 5 Hours </option>
            <option value={6}> 6 Hours </option>
            <option value={7}> 7 Hours </option>
            <option value={8}> 8 Hours </option>
            <option value={9}> 9 Hours </option>
            <option value={10}> 10 Hours </option>
          </select>
        </div>
        <div className='d-flex justify-content-end'>
          <button className='btn btn-sm fw-bold btn-primary' type='submit'>
            Search
          </button>
        </div>
      </div>
    </form>
  )
}

const SchedulesTable: React.FC<Props> = ({
  className,
  depIcao: depProp,
  arrIcao: arrProp,
  onChange,
}) => {
  const useUrlQuery = () => {
    const query = new URLSearchParams(useLocation().search)
    return {
      depIcao: query.get('depIcao'),
      arrIcao: query.get('arrIcao'),
      aircraftType: query.get('aircraftType'),
    }
  }

  const [types, setTypes] = useState<string[]>([])
  const {data, isLoading, isError, refetch} = useQuery(
    'getSchedules',
    () => getSchedules(searchForm.values),
    {
      onSuccess(data) {
        if (data.length === 0) {
          setAddScheduleIsError(true)
          setMsg('No schedules found')
        } else {
          setMsg('')
          setAddScheduleIsError(false)
          // Set aircraft filter options
          if (!types.length) {
            const aircraftSet = new Set()
            data.filter((s: any) => {
              if (aircraftSet.has(s.aircraft_type)) return false
              aircraftSet.add(s.aircraft_type)
              return true
            })

            setTypes(Array.from(aircraftSet) as string[])
          }
        }
        onChange && onChange(data)
      },
    }
  )

  // const [addScheduleIsLoading, setAddScheduleIsLoading] = useState(false)
  const [addScheduleIsError, setAddScheduleIsError] = useState(false)
  const [limit, setLimit] = useState(500)
  const incLimit = 500
  const [msg, setMsg] = useState('')
  const formik = useFormik<IAddSchedule>({
    initialValues: {
      ...addSchedule,
    },
    onSubmit: async () => {
      // setAddScheduleIsLoading(true)
      if (data.find((s: ScheduleData) => s.public_id === formik.values.public_id)?.added) {
        setAddScheduleIsError(true)
        setMsg('Schedule has already been added!')
        setTimeout(() => {
          setMsg('')
        }, 5000)
        return
      }

      const bid = await createBid(formik.values.public_id)

      if (!bid) {
        setAddScheduleIsError(true)
        setMsg('Schedule could not be added! It may already be booked.')
        setTimeout(() => {
          setMsg('')
        }, 5000)
        return
      }

      setAddScheduleIsError(false)
      setTimeout(() => {
        // setAddScheduleIsLoading(false)
        setMsg('')
        data.map((s: ScheduleData) => s.public_id === formik.values.public_id && (s.added = true))
      }, 5000)
      setMsg('Schedule has been successfully added!')
    },
  })

  const {depIcao, arrIcao, aircraftType} = useUrlQuery()

  const searchForm = useFormik<ISearchSchedules>({
    initialValues: {
      ...searchSchedulesInitial,
      ...(depIcao && {
        dep: depIcao.toUpperCase(),
      }),
      ...(arrIcao && {
        arr: arrIcao.toUpperCase(),
      }),
      ...(depProp && {
        dep: depProp.toUpperCase(),
      }),
      ...(arrProp && {
        arr: arrProp.toUpperCase(),
      }),
      ...(aircraftType && {
        aircraftType: aircraftType.toUpperCase(),
      }),
    },
    validationSchema: searchSchedulesSchema,
    onSubmit: () => {
      // Strip empty values
      Object.keys(searchForm.values).forEach(
        (key) => (searchForm.values as any)[key] === '' && delete (searchForm.values as any)[key]
      )

      refetch()
    },
  })

  useEffect(() => {
    // When depIcao or arrIcao changes, update form and submit
    if (depProp || arrProp) {
      searchForm.setFieldValue('dep', depProp)
      searchForm.setFieldValue('arr', arrProp)
      setTimeout(() => {
        searchForm.handleSubmit()
      }, 200)
    }
  }, [depProp, arrProp])

  return (
    <>
      <Alert error={addScheduleIsError} msg={msg} />
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Flight Schedules</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>
              Over {data?.length ? data.length - (data.length % 10) || data.length : 0} schedules
            </span>
          </h3>
          <div className='card-toolbar'>
            <SearchForm formik={searchForm} types={types} />
          </div>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Flight Number</th>
                  <th className='min-w-140px'>Route</th>
                  <th className='min-w-120px'>Equipment</th>
                  <th className='min-w-120px'>Flight Time</th>
                  <th className='min-w-120px'>Notes</th>
                  <th className='min-w-120px'>Last Updated</th>
                  <th className='min-w-100px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {data?.length && !isLoading ? (
                  // get first n records
                  data.slice(0, limit).map((item: ScheduleData, i: number) => (
                    <tr key={'schedule-' + item.public_id}>
                      <td>
                        <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                          {item.callsign + item.flight_number} <br />
                          {item?.callsign_2 && (
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {item?.callsign_2 + (item?.flight_number_2 || item?.flight_number)}
                            </span>
                          )}
                        </a>
                      </td>
                      <td style={{maxWidth: 400}}>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item.dep_icao + ' - ' + item.arr_icao}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {`Departure Gate: ${item.dep_gate || 'NA'}`}
                        </span>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          {`Arrival Gate: ${item.arr_gate || 'NA'}`}
                        </span>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          Route: {item.route || 'NA'}
                        </span>
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item.aircraft_type}
                        </a>
                        {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Code: Paid
                      </span> */}
                      </td>
                      <td>
                        <a
                          href='#'
                          className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                        >
                          {item.est_flight_time}
                        </a>
                        <span className='text-muted fw-semibold text-muted d-block fs-7'>
                          Hours
                        </span>
                      </td>
                      <td className='text-dark text-hover-primary fs-6'>{item.notes}</td>
                      <td>
                        {/* if updated date is within 30 days shot green badge */}
                        {moment(item.updated_at).isAfter(moment().subtract(30, 'days')) ? (
                          <span className='badge badge-light-success'>
                            {moment(item.updated_at).fromNow()}
                          </span>
                        ) : (
                          <span className='badge badge-light-warning'>
                            {moment(item.updated_at).fromNow()}
                          </span>
                        )}
                      </td>
                      <td className='text-end'>
                        <button
                          onClick={() => {
                            formik.setFieldValue('public_id', item.public_id)
                            return formik.handleSubmit()
                          }}
                          className={
                            item.added
                              ? 'btn btn-icon bg-success btn-active-color-primary btn-sm me-1'
                              : 'btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          }
                        >
                          <KTSVG
                            path='/media/icons/duotune/abstract/abs011.svg'
                            className='svg-icon-3'
                          />
                        </button>
                        {/* <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </a>
                  <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </a> */}
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan={7}>Loading Flights...</td>
                  </tr>
                )}
                {
                  // if length is greater than n show load more button. and center btton
                  data?.length > limit && (
                    <tr>
                      <td colSpan={7} className='text-center'>
                        <button
                          onClick={() => {
                            setLimit(limit + incLimit)
                          }}
                          className='btn btn-primary'
                        >
                          Load More
                        </button>
                      </td>
                    </tr>
                  )
                }
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {SchedulesTable}
