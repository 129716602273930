/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react'
import {useQuery} from 'react-query'
import {getBids, deleteBid, getSimbriefByBidId, getUserStats} from '../../../../middleware'
import {KTSVG} from '../../../helpers'

import moment from 'moment'
import {useFormik} from 'formik'
import {DocView} from '../DocView'

type Props = {
  className: string
}

type ScheduleData = {
  public_id: string
  active: boolean
  aircraft_type: string
  altitude: number
  arr_icao: string
  dep_icao: string
  callsign: string
  flight_number: number
  callsign_2?: string
  flight_number_2?: number
  est_flight_time: number
  notes: string
  route: string
  updated_at: string
  simbrief?: any
  bidSelected?: boolean
}

interface IDeleteBid {
  public_id: string
}

const deleteBidData = {
  public_id: '',
}

const Alert: React.FC<{error: boolean; msg: string}> = ({error, msg}) => {
  if (!msg) return <></>
  if (error) {
    return <div className='badge badge-light-danger p-12'>{msg}</div>
  }
  return <div className='badge badge-light-success p-12'>{msg}</div>
}

const BookedFlightsTable: React.FC<Props> = ({className}) => {
  const [types, setTypes] = useState<string[]>([])
  const [simbriefSchedules, setSimbriefSchedules] = useState<any>([])

  const {
    data,
    isLoading,
    isError: _,
    refetch,
  } = useQuery('getBids', getBids, {
    refetchOnWindowFocus: false,
    enabled: false,
    onSuccess: async (data) => {
      if (data.length === 0) {
        setDeleteBidIsError(true)
        setMsg('No schedules found')
      } else {
        setMsg('')
        setDeleteBidIsError(false)
        // Set aircraft filter options
        if (!types.length) {
          const aircraftSet = new Set()
          data.filter((s: any) => {
            if (aircraftSet.has(s.aircraft_type)) return false
            aircraftSet.add(s.aircraft_type)
            return true
          })

          setTypes(Array.from(aircraftSet) as string[])
        }

        const openBrief = data.find((s: any) => s.bidSelected)
        if (openBrief) {
          return false
        }

        const newData = await Promise.all(
          data.map(async (s: any) => {
            if (!userData?.simbrief_id) return s
            const simbrief = await getSimbriefByBidId(s.public_id, userData.simbrief_id)
            if (simbrief) {
              s.simbrief = simbrief
              s.bidSelected = false
            }
            return s
          })
        )
        setSimbriefSchedules(newData)
      }
    },
  })

  // Geet user data then getBids
  const {data: userData} = useQuery('userData', getUserStats, {
    onSuccess: refetch,
  })

  // const [addScheduleIsLoading, setAddScheduleIsLoading] = useState(false)
  const [deleteBidIsError, setDeleteBidIsError] = useState(false)
  const [limit, setLimit] = useState(500)
  const incLimit = 500
  const [msg, setMsg] = useState('')
  const formik = useFormik<IDeleteBid>({
    initialValues: {
      ...deleteBidData,
    },
    onSubmit: async () => {
      // setAddScheduleIsLoading(true)
      if (data.find((s: any) => s.public_id === formik.values.public_id)?.deleted) {
        setDeleteBidIsError(true)
        setMsg('Bid has already been deleted!')
        setTimeout(() => {
          setMsg('')
        }, 5000)
        return
      }

      const bid = await deleteBid(formik.values.public_id)

      if (!bid) {
        setDeleteBidIsError(true)
        setMsg('Bid could not be deleted! It may not exist.')
        setTimeout(() => {
          setMsg('')
        }, 5000)
        return
      }

      setDeleteBidIsError(false)
      setTimeout(() => {
        // setAddScheduleIsLoading(false)
        setMsg('')
        data.map((s: any) => s.public_id === formik.values.public_id && (s.deleted = true))
      }, 2500)
      setMsg('Bid has been successfully deleted!')
    },
  })

  const mapData = simbriefSchedules ?? data

  return (
    <>
      <Alert error={deleteBidIsError} msg={msg} />
      <div className={`card ${className}`}>
        {/* begin::Header */}
        <div className='card-header border-0 pt-5'>
          <h3 className='card-title align-items-start flex-column'>
            <span className='card-label fw-bold fs-3 mb-1'>Booked Flights</span>
            <span className='text-muted mt-1 fw-semibold fs-7'>{data?.length || 0} bids</span>
          </h3>
        </div>
        {/* end::Header */}
        {/* begin::Body */}
        <div className='card-body py-3'>
          {/* begin::Table container */}
          <div className='table-responsive'>
            {/* begin::Table */}
            <table className='table table-row-bordered table-row-gray-100 align-middle gs-0 gy-3'>
              {/* begin::Table head */}
              <thead>
                <tr className='fw-bold text-muted'>
                  <th className='min-w-150px'>Flight Number</th>
                  <th className='min-w-140px'>Route</th>
                  <th className='min-w-120px'>Equipment</th>
                  <th className='min-w-120px'>Flight Time</th>
                  <th className='min-w-120px'>Notes</th>
                  <th className='min-w-120px'>Last Updated</th>
                  <th className='min-w-150px text-end'>Actions</th>
                </tr>
              </thead>
              {/* end::Table head */}
              {/* begin::Table body */}
              <tbody>
                {mapData?.length && !isLoading ? (
                  // get first n records
                  mapData.slice(0, limit).map(
                    (
                      item: {
                        schedule: ScheduleData
                        public_id: string
                        deleted?: boolean
                        simbrief?: any
                        bidSelected?: boolean
                      },
                      i: number
                    ) => (
                      <>
                        <tr key={'schedule-' + item.schedule.public_id}>
                          <td>
                            <a href='#' className='text-dark fw-bold text-hover-primary fs-6'>
                              {item.schedule.callsign + item.schedule.flight_number} <br />
                              {item.schedule?.callsign_2 && (
                                <span className='text-muted fw-semibold text-muted d-block fs-7'>
                                  {item.schedule?.callsign_2 +
                                    (item.schedule?.flight_number_2 ||
                                      item.schedule?.flight_number)}
                                </span>
                              )}
                            </a>
                          </td>
                          <td style={{maxWidth: 400}}>
                            <a
                              href='#'
                              className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                            >
                              {item.schedule.dep_icao + ' - ' + item.schedule.arr_icao}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              {item.schedule.route}
                            </span>
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                            >
                              {item.schedule.aircraft_type}
                            </a>
                            {/* <span className='text-muted fw-semibold text-muted d-block fs-7'>
                        Code: Paid
                      </span> */}
                          </td>
                          <td>
                            <a
                              href='#'
                              className='text-dark fw-bold text-hover-primary d-block mb-1 fs-6'
                            >
                              {item.schedule.est_flight_time}
                            </a>
                            <span className='text-muted fw-semibold text-muted d-block fs-7'>
                              Hours
                            </span>
                          </td>
                          <td className='text-dark text-hover-primary fs-6'>
                            {item.schedule.notes}
                          </td>
                          <td>
                            {/* if updated date is within 30 days shot green badge */}
                            {moment(item.schedule.updated_at).isAfter(
                              moment().subtract(30, 'days')
                            ) ? (
                              <span className='badge badge-light-success'>
                                {moment(item.schedule.updated_at).fromNow()}
                              </span>
                            ) : (
                              <span className='badge badge-light-warning'>
                                {moment(item.schedule.updated_at).fromNow()}
                              </span>
                            )}
                          </td>
                          <td className='text-end'>
                            {!item.simbrief ? (
                              <a
                                className='btn btn-bg-light btn-active-color-primary btn-sm me-3 px-5'
                                // onClick={(e) => deleteFlight(e, bid.public_id)}
                                href={`http://www.simbrief.com/system/dispatch.php?airline=${
                                  item?.schedule.callsign || ''
                                }&fltnum=${item?.schedule.flight_number}&type=${
                                  item?.schedule.aircraft_type
                                }&orig=${item?.schedule.dep_icao}&dest=${item?.schedule.arr_icao}${
                                  item?.schedule.route ? `&route=${item?.schedule.route}` : ''
                                }&static_id=${item?.public_id}&planformat=${
                                  item?.schedule.callsign || 'LIDO'
                                }&manualrmk=${window.location.origin
                                  .replace('https://', '')
                                  .replace('http://', '')}`}
                                target='_blank'
                                rel='noopener'
                              >
                                Dispatch
                              </a>
                            ) : (
                              <>
                                {userData.simbrief_id && (
                                  <a
                                    className='btn btn-bg-light btn-active-color-primary btn-sm me-3 px-5'
                                    onClick={(e) => {
                                      e.preventDefault()
                                      if (!item.bidSelected) {
                                        item.bidSelected = true
                                      } else {
                                        item.bidSelected = false
                                      }
                                      setSimbriefSchedules(
                                        simbriefSchedules.map((b: any) => {
                                          if (b.public_id === item.public_id) {
                                            return item
                                          }
                                          return b
                                        })
                                      )
                                    }}
                                    target='_blank'
                                    rel='noopener'
                                  >
                                    {!item.bidSelected ? 'View Brief' : 'Hide Brief'}
                                  </a>
                                )}
                              </>
                            )}
                            <button
                              onClick={() => {
                                formik.setFieldValue('public_id', item.public_id)
                                return formik.handleSubmit()
                              }}
                              className={
                                item.deleted
                                  ? 'btn btn-icon btn-bg-danger btn-active-color-primary btn-sm me-1'
                                  : 'btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                              }
                            >
                              <KTSVG
                                path='/media/icons/duotune/abstract/abs012.svg'
                                className='svg-icon-3'
                              />
                            </button>
                            {/* <a
                    href='#'
                    className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                  >
                    <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                  </a>
                  <a href='#' className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'>
                    <KTSVG path='/media/icons/duotune/general/gen027.svg' className='svg-icon-3' />
                  </a> */}
                          </td>
                        </tr>
                        {item.bidSelected && (
                          <tr
                            className='uk-margin-medium-top'
                            style={{
                              width: '100%',
                              position: 'relative',
                              height: '80vh',
                            }}
                          >
                            <DocView
                              style={{width: '100%', height: '80vh', position: 'absolute', top: 0}}
                              url={item.simbrief.files.directory + item.simbrief.files.pdf.link}
                              id={item.public_id}
                            />
                          </tr>
                        )}
                      </>
                    )
                  )
                ) : (
                  <tr>
                    <td colSpan={7}>Loading Bids...</td>
                  </tr>
                )}
                {
                  // if length is greater than n show load more button. and center btton
                  mapData?.length > limit && (
                    <tr>
                      <td colSpan={7} className='text-center'>
                        <button
                          onClick={() => {
                            setLimit(limit + incLimit)
                          }}
                          className='btn btn-primary'
                        >
                          Load More
                        </button>
                      </td>
                    </tr>
                  )
                }
              </tbody>
              {/* end::Table body */}
            </table>
            {/* end::Table */}
          </div>
          {/* end::Table container */}
        </div>
        {/* begin::Body */}
      </div>
    </>
  )
}

export {BookedFlightsTable}
