import {MenuItem} from './MenuItem'
import {MenuInnerWithSub} from './MenuInnerWithSub'
import clsx from 'clsx'
import {checkIsActive, KTSVG} from '../../../helpers'
import {flagEnableJumpseat} from '../../../../middleware/flags'

export function MenuInner() {
  return (
    <>
      <MenuInnerWithSub
        title='Crewbases'
        to='/crew'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/airport/kord' title="Chicago O'Hare" hasBullet={true} />
        <MenuItem to='/airport/kewr' title='Newark' hasBullet={true} />
        <MenuItem to='/airport/kden' title='Denver' hasBullet={true} />
        <MenuItem to='/airport/kiah' title='Houston–Intercontinental' hasBullet={true} />
        <MenuItem to='/airport/kiad' title='Washington Dulles' hasBullet={true} />
        <MenuItem to='/airport/klas' title='Las Vegas' hasBullet={true} />
        <MenuItem to='/airport/klax' title='Los Angeles' hasBullet={true} />
        <MenuItem to='/airport/kmco' title='Orlando' hasBullet={true} />
        <MenuItem to='/airport/ksfo' title='San Francisco' hasBullet={true} />
        <MenuItem to='/airport/pgum' title='Guam' hasBullet={true} />
      </MenuInnerWithSub>

      <MenuItem to='/roster' title='Roster' />

      <MenuInnerWithSub
        title='Flight Planning'
        to='/crafted'
        menuPlacement='bottom-start'
        menuTrigger='click'
      >
        <MenuItem to='/schedules' title='Schedules' hasBullet={true} />
        <MenuItem to='/pbs' title='Trip Request' hasBullet={true} />
        <MenuItem to='/bid-lines' title='My Trips' hasBullet={true} />
        <MenuItem to='/manage' title='Booked Flights' hasBullet={true} />
        <MenuItem to='/route-map' title='Route Map' hasBullet={true} />
        {flagEnableJumpseat() && <MenuItem to='/jumpseat' title='Jumpseat' hasBullet={true} />}
        <MenuItem to='/route-changelog' title='Route Changelog' hasBullet={true} />
      </MenuInnerWithSub>

      <MenuItem to='/flights' title='Logbook' />
      <MenuItem to='/live-map' title='Live Map' />
      <MenuItem to='/events' title='Events' />
      <div className='menu-item me-lg-1'>
        <a className={clsx('menu-link py-3')} href={'https://help.vuainc.org/'} target='_blank'>
          <span className='menu-icon '>
            <KTSVG
              path={'/media/icons/duotune/communication/com012.svg'}
              className='text-white svg-icon-1'
            />
          </span>

          <span className='menu-title'>Help Desk</span>
        </a>
      </div>

      <div className='menu-item me-lg-1'>
        <a className={clsx('menu-link py-3')} href={'https://news.vuainc.org/'} target='_blank'>
          <span className='menu-icon '>
            <KTSVG
              path={'/media/icons/duotune/general/gen016.svg'}
              className='text-white svg-icon-1'
            />
          </span>

          <span className='menu-title'>News Center</span>
        </a>
      </div>

      <div className='menu-item me-lg-1'>
        <a
          className={clsx('menu-link py-3')}
          href={
            'https://github.com/Rotate-Group/vua-acars-release/releases/download/v1.0.0/VUAcars-Setup-1.0.0.exe'
          }
        >
          <span className='menu-icon '>
            <KTSVG
              path={'/media/icons/duotune/files/fil021.svg'}
              className='text-white svg-icon-1'
            />
          </span>

          <span className='menu-title'>vUACARS</span>
        </a>
      </div>
      {/* <MenuItem to='/apps/chat/group-chat' title='Challenges' />
      <MenuItem to='/apps/chat/group-chat' title='Statistics' />
      <MenuItem to='/apps/chat/group-chat' title='Events' />
      <MenuItem to='/apps/chat/group-chat' title='Resources' /> */}
    </>
  )
}
