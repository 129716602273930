import daygrid from '@fullcalendar/daygrid'
import FullCalendar from '@fullcalendar/react'
import {PageTitle, PageLink} from '../../../_metronic/layout/core'
import interactionPlugin from '@fullcalendar/interaction'
import {useQuery} from 'react-query'
import {getCarrierEvents, getVatsimEvents} from '../../../middleware'
import {useCallback, useEffect, useMemo, useState} from 'react'
import {Image, Modal} from 'react-bootstrap'
import {KTSVG} from '../../../_metronic/helpers'
import moment from 'moment'

const cacheTime = 5 * 60000
const breadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

type Props = any

const SelectLocationModal: React.FC<Props> = ({show, handleClose, data}) => {
  const dissmissLocation = () => {
    handleClose()
  }
  const applyLocation = () => {
    handleClose()
  }

  return (
    <Modal
      className='modal fade'
      id='kt_modal_select_location'
      data-backdrop='static'
      tabIndex={-1}
      role='dialog'
      show={show}
      dialogClassName='modal-xl'
      aria-hidden='true'
      onHide={dissmissLocation}
    >
      <div className='modal-content'>
        <div className='modal-header'>
          <h5 className='modal-title'>
            {data?.name}
            <br></br>
            <span className='text-muted'>
              {moment(data?.start_time).format('MMMM DD Y h:mm a')} -{' '}
              {moment(data?.end_time).format('h:mm a')}
            </span>
          </h5>

          <div
            className='btn btn-icon btn-sm btn-active-light-primary ms-2'
            onClick={dissmissLocation}
          >
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2x' />
          </div>
        </div>
        <div className='modal-body'>
          <div className='row g-5 g-xl-8'>
            <div className='col-xl-6'>
              <div className='fw-bolder fs-5 mb-2'>
                <Image src={data?.banner} fluid />
              </div>
            </div>
            <div className='col-xl-6'>
              <div className='fw-bolder fs-5 mb-2'></div>
              <h6 className='fs-4'>{data?.name}</h6>
              {data?.airports?.length && (
                <div className='fs-4 mb-2'>
                  Airports: {data?.airports?.map((a: any) => a.icao).join(' ')}
                </div>
              )}
              <div
                className='fs-4 text-muted'
                dangerouslySetInnerHTML={{
                  __html: data?.description,
                }}
              ></div>
              {/* <div className='mb-2 fs-5'>
                Start Time: <br></br>
                {moment(data?.start_time).format('MMMM DD Y h:mm a')}
              </div>
              <div>
                End Time<br></br>
                {moment(data?.end_time).format('MMMM DD Y h:mm a')}
              </div> */}
              <a rel='nofollow' target={'_blank'} className='fs-4' href={data?.link || '#'}>
                {data?.link}
              </a>
            </div>
          </div>
        </div>
        <div className='modal-footer'>
          <button type='button' className='btn btn-light-primary' onClick={dissmissLocation}>
            Close
          </button>
          {/* <button id='submit' type='button' className='btn btn-primary' onClick={applyLocation}>
            Visit Event Webpage
          </button> */}
        </div>
      </div>
    </Modal>
  )
}

function renderEventContent(eventInfo: any) {
  return (
    <>
      <div
        style={{
          cursor: 'pointer',
          color: '#fff',
          backgroundColor: eventInfo?.event._def.extendedProps?.bgColor ?? '#77c35c',
          height: 40,
          width: '100%',
          overflow: 'hidden',
        }}
        className='p-1'
      >
        <b>
          {eventInfo.timeText} - {eventInfo?.event._def.extendedProps?.group} Event
        </b>{' '}
        <br></br>
        <i>{eventInfo.event.title}</i>
      </div>
    </>
  )
}

const handleDateClick = (arg: any) => {
  // bind with an arrow function
  alert('test')
}
const EventCalendarPage = () => {
  const [selectedEvent, setSelectedEvent] = useState<any>(null)
  const {data: vatsimEventsData} = useQuery('vatsimEventsData', getVatsimEvents, {})
  const {data: carrierEventsData} = useQuery('carrierEventsData', getCarrierEvents, {})

  useEffect(() => {}, [vatsimEventsData, getCarrierEvents])

  const events = useMemo(() => {
    return vatsimEventsData?.map((event: any) => {
      return {
        ...event,
        title: event.name,
        group: 'Vatsim',
        date: event.start_time,
        bgColor: '#77c35c',
      }
    })
  }, [vatsimEventsData])

  const carrierEvents = useMemo(() => {
    return carrierEventsData?.map((event: any) => {
      return {
        ...event,
        group: 'Carrier',
        date: event.start_date,
        bgColor: '#5a5ae0',
        banner: event?.image_url,
        start_time: event?.start_date,
        end_time: event?.end_date,
        name: event?.title,
      }
    })
  }, [carrierEventsData])

  const allEvents = useMemo(() => {
    const all = []
    if (events?.length) {
      all.push(...events)
    }
    if (carrierEvents?.length) {
      all.push(...carrierEvents)
    }
    return all
  }, [events, carrierEvents])

  const handleEventClick = useCallback(
    (event: any) => {
      setSelectedEvent(event.event._def.extendedProps)
    },
    [events, carrierEvents]
  )

  const handleClose = useMemo(() => {
    return () => {
      setSelectedEvent(null)
    }
  }, [selectedEvent])

  return (
    <>
      <div className='row g-5 g-xl-8'>
        <div className='col-12'>
          <div className='card mb-5 mb-xl-10 p-10'>
            <FullCalendar
              plugins={[daygrid, interactionPlugin]}
              initialView='dayGridMonth'
              events={allEvents}
              eventClick={handleEventClick}
              eventContent={renderEventContent}
            />
            <SelectLocationModal
              show={!!selectedEvent}
              handleClose={handleClose}
              data={selectedEvent}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const EventCalendarWrapper = () => {
  return (
    <>
      <PageTitle breadcrumbs={breadCrumbs}>{'Events Calendar'}</PageTitle>
      <EventCalendarPage />
    </>
  )
}

export {EventCalendarWrapper}
