export const CARRIER_ID = '679beae9-e761-4a68-b858-7f1ab58fd0f4'
export const SSO_URL = 'https://sso.rotate.group/'
export const CHECK_WX_API_KEY = '5f834ed10513dd66e5d3850fb5'
export const CARRIER_CALLSIGN = 'UAL'
export const MAPBOX_URI =
  'https://api.mapbox.com/styles/v1/edwinbrowwn/clk93pnzz033t01pa31imfwu7/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoiZWR3aW5icm93d24iLCJhIjoiY2trcWs0YWZjMDg0bzJ1cXQyODB1NnNydSJ9.tlLwEw7sB5aNaHxQTpqkRw'
export let API_URL =
  process.env.NODE_ENV === 'development'
    ? //'http://localhost:8787/'
      'https://api-v2.rotate.group/'
    : 'https://api-v2.rotate.group/'

export const socialLinks = [
  {
    title: 'Facebook',
    link: 'https://www.facebook.com/virtualuainc',
  },
  {
    title: 'Twitter',
    link: 'https://twitter.com/virtualUA',
  },
  {
    title: 'Instagram',
    link: 'https://www.instagram.com/virtualuainc/',
  },
  {
    title: 'YouTube',
    link: 'https://www.youtube.com/c/VirtualuaOrg',
  },
  {
    title: 'Twitch',
    link: 'https://www.twitch.tv/virtualua',
  },
  {
    title: 'Snapchat',
    link: 'https://www.snapchat.com/add/virtualua',
  },
  {
    title: 'Facebook Group',
    link: 'https://www.facebook.com/groups/virtualuainc',
  },
]

export const skipAuthPage = false
