import {useCallback, useEffect, useMemo, useRef, useState} from 'react'
import {KTSVG} from '../../../../_metronic/helpers'
import {Step1} from './steps/Step1'
import {Step2} from './steps/Step2'
import {Step3} from './steps/Step3'
import {Step4} from './steps/Step4'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {Formik, Form, FormikValues} from 'formik'
import {IbidLineQuery, bidLineQuerySchemas, inits} from './CreateBidLineWizardHelper'
import {useQuery} from 'react-query'
import {generateBidLine, getBidLine, getCarrierInfo, saveBidLine} from '../../../../middleware'
import {flagEnableDeadhead} from '../../../../middleware/flags'

const typeGroupings = [
  {name: 'Airbus 319/320/21N', id: 'A319,A320,A21N'},
  {name: 'Boeing 737/738/739', id: 'B737,B738,B739'},
  {name: 'Boeing 737MAX8/737MAX9/737MAX10', id: 'B38M,B39M,B3XM'},
  {name: 'Boeing 757/767', id: 'B752,B753,B763,B764'},
  {name: 'Boeing 777/787', id: 'B772,B77W,B788,B789,B78X'},
  {name: 'Embraer 145/170/175', id: 'E145,E170,E175'},
  {name: 'CRJ 200/700/900', id: 'CRJ2,CRJ7,CRJ9'},
]

const LineAlreadyExists = () => {
  return (
    <div className='notice d-flex bg-light-warning rounded border-warning border border-solid p-6'>
      <KTSVG
        path='/media/icons/duotune/general/gen037.svg'
        className='svg-icon-2tx svg-icon-warning me-4'
      />
      <div className='d-flex flex-stack flex-grow-1'>
        <div className='fw-semibold'>
          <h4 className='text-gray-800 fw-bold'>Unable to generate bid line!</h4>
          <div className='fs-6 text-gray-600'>
            Bid line in progress. You can view the progress of your bid line by clicking the button
            below.<br></br>
            <a href='/bid-lines' className='fw-bold'>
              {' '}
              View Bid Line
            </a>
          </div>
        </div>
      </div>
    </div>
  )
}

const VerticalBidLine = () => {
  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [currentSchema, setCurrentSchema] = useState(bidLineQuerySchemas[0])
  const [initValues] = useState<IbidLineQuery>(inits)
  const [generatedLine, setGeneratedLine] = useState<any>([])
  const [generatedLineIsLoading, setGeneratedLineIsLoading] = useState(false)

  const enableDeadhead = flagEnableDeadhead()

  const {
    data: carrierData,
    isLoading: carrierIsLoading,
    error: carrierError,
  } = useQuery('carrierData', () => getCarrierInfo(), {
    refetchOnWindowFocus: false,
    cacheTime: 60 * 60000,
  })

  const {
    data: bidLineData,
    isLoading: bidLineIsLoading,
    error: bidLineError,
  } = useQuery('bidLineData', getBidLine, {})

  const checkValidation = useCallback(() => {
    return ''
  }, [])

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }

    stepper.current.goPrev()

    setCurrentSchema(bidLineQuerySchemas[stepper.current.currentStepIndex - 1])
  }

  const submitStep = async (values: IbidLineQuery, actions: FormikValues) => {
    if (!stepper.current) {
      return
    }

    let regenerateLine = false

    if (values._regen) {
      regenerateLine = true
    }
    delete values._regen

    // If current step is 2 then submit the form
    if (stepper.current.currentStepIndex === 2 || regenerateLine) {
      setGeneratedLineIsLoading(true)
      const generated = await generateBidLine({
        ...values,
        legCount: Number(values.legCount),
        depIcao: values.depIcao?.toUpperCase(),
      })
      setGeneratedLine(generated)
      setGeneratedLineIsLoading(false)
    }

    if (regenerateLine) {
      return
    }

    // If step is 3 then submit the form saveBidLine
    if (stepper.current.currentStepIndex === 3) {
      const save = await saveBidLine(generatedLine.map, enableDeadhead && generatedLine.deadheadMap)
      if (save.public_id) {
        actions.resetForm()
      } else {
        alert('Error accepting line')
        return false
      }
    }

    setCurrentSchema(bidLineQuerySchemas[stepper.current.currentStepIndex])

    if (stepper.current.currentStepIndex !== stepper.current.totatStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
  }

  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])

  const operators = useMemo(() => {
    if (carrierData?.settings?.schedules_operators) {
      return carrierData.settings.schedules_operators
    }
    return []
  }, [carrierData?.settings?.schedules_operators])

  const aircraftTypes = useMemo(() => {
    if (carrierData?.settings?.airframes) {
      return [...typeGroupings, ...carrierData.settings.airframes]
    }
    return typeGroupings
  }, [carrierData?.settings?.airframes])

  const sequence = useMemo(() => {
    if (generatedLine?.sequence) {
      return generatedLine.sequence
    }
    return []
  }, [generatedLine])

  const deadheadMap = useMemo(() => {
    if (generatedLine?.deadheadMap) {
      return generatedLine.deadheadMap
    }
    return []
  }, [generatedLine])

  return (
    <div
      ref={stepperRef}
      className='stepper stepper-pills stepper-column d-flex flex-column flex-xl-row flex-row-fluid'
      id='kt_create_account_stepper'
    >
      {/* begin::Aside*/}
      <div className='card d-flex justify-content-center justify-content-xl-start flex-row-auto w-100 w-xl-300px w-xxl-400px me-9'>
        {/* begin::Wrapper*/}
        <div className='card-body px-6 px-lg-10 px-xxl-15 py-20'>
          {/* begin::Nav*/}
          <div className='stepper-nav'>
            {/* begin::Step 1*/}
            <div className='stepper-item current' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>1</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Carrier</h3>

                  <div className='stepper-desc fw-semibold'>Select a Carrier</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 1*/}

            {/* begin::Step 2*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>2</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Bid Details</h3>
                  <div className='stepper-desc fw-semibold'>
                    Setup preferences for line generation
                  </div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 2*/}

            {/* begin::Step 3*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>3</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Preview Line</h3>
                  <div className='stepper-desc fw-semibold'>Your generated line</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 3*/}

            {/* begin::Step 4*/}
            <div className='stepper-item' data-kt-stepper-element='nav'>
              {/* begin::Wrapper*/}
              <div className='stepper-wrapper'>
                {/* begin::Icon*/}
                <div className='stepper-icon w-40px h-40px'>
                  <i className='stepper-check fas fa-check'></i>
                  <span className='stepper-number'>4</span>
                </div>
                {/* end::Icon*/}

                {/* begin::Label*/}
                <div className='stepper-label'>
                  <h3 className='stepper-title'>Confirmed</h3>
                  <div className='stepper-desc fw-semibold'>All set, ready to fly</div>
                </div>
                {/* end::Label*/}
              </div>
              {/* end::Wrapper*/}

              {/* begin::Line*/}
              <div className='stepper-line h-40px'></div>
              {/* end::Line*/}
            </div>
            {/* end::Step 4*/}
          </div>
          {/* end::Nav*/}
        </div>
        {/* end::Wrapper*/}
      </div>
      {/* begin::Aside*/}

      <div className='d-flex flex-row-fluid flex-center bg-body rounded'>
        {bidLineData?.[0]?.map ? (
          <LineAlreadyExists />
        ) : (
          <Formik validationSchema={currentSchema} initialValues={initValues} onSubmit={submitStep}>
            {() => (
              <Form className='py-20 w-100 w-xl-700px px-9' noValidate id='kt_create_account_form'>
                <div className='current' data-kt-stepper-element='content'>
                  <Step1 operators={operators} />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step2 aircraftTypes={aircraftTypes} />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step3
                    schedules={sequence}
                    deadheadMap={deadheadMap}
                    loading={generatedLineIsLoading}
                  />
                </div>

                <div data-kt-stepper-element='content'>
                  <Step4 />
                </div>

                <div className='d-flex flex-stack pt-10'>
                  <div className='mr-2'>
                    <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                    >
                      <KTSVG
                        path='/media/icons/duotune/arrows/arr063.svg'
                        className='svg-icon-4 me-1'
                      />
                      Back
                    </button>
                  </div>

                  <div>
                    {stepper?.current?.currentStepIndex !== 4 && (
                      <button type='submit' className='btn btn-lg btn-primary me-3'>
                        <span className='indicator-label'>
                          {stepper.current?.currentStepIndex !==
                            stepper.current?.totatStepsNumber! - 1 &&
                            stepper?.current?.currentStepIndex !== 3 &&
                            'Continue'}
                          {stepper.current?.currentStepIndex === 3 && 'Confirm'}
                          {stepper.current?.currentStepIndex ===
                            stepper.current?.totatStepsNumber! - 1 &&
                            stepper?.current?.currentStepIndex !== 3 &&
                            'Submit'}
                          <KTSVG
                            path='/media/icons/duotune/arrows/arr064.svg'
                            className='svg-icon-3 ms-2 me-0'
                          />
                        </span>
                      </button>
                    )}
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  )
}

export {VerticalBidLine}
