/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useLayout} from '../core'
import {socialLinks} from '../../../config'

const Footer: FC = () => {
  const {classes} = useLayout()
  return (
    <div className='footer py-4 d-flex flex-lg-column' id='kt_footer'>
      {/* begin::Container */}
      <div
        className={`${classes.footerContainer} d-flex flex-column flex-md-row align-items-center justify-content-between`}
      >
        {/* begin::Copyright */}
        <div className='text-dark order-2 order-md-1'>
          <span className='text-muted fw-semibold me-2'>
            {' '}
            &copy; 2012 - {new Date().getFullYear()}
          </span>
          <a href='#' className='text-gray-800 text-hover-primary'>
            VirtualUA, Inc. | VUA, Inc. is a 501(c)3 non-profit organization. United Airlines does
            not sponsor us. All content belongs to its owners
          </a>
        </div>
        {/* end::Copyright */}

        {/* begin::Nav */}
        {socialLinks.length && (
          <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
            {socialLinks.map((link, index) => (
              <li className='menu-item'>
                <a
                  href={link.link}
                  className='menu-link ps-0 pe-2'
                  target='_blank'
                  rel='noreferrer'
                >
                  <span className='menu-icon'>
                    <i className={`bi bi-${link.title.toLowerCase()}`} style={{fontSize: 18}}></i>
                  </span>
                </a>
              </li>
            ))}
          </ul>
        )}
        {/* <ul className='menu menu-gray-600 menu-hover-primary fw-semibold order-1'>
          <li className='menu-item'>
            <a href='#' className='menu-link ps-0 pe-2'>
              About
            </a>
          </li>
          <li className='menu-item'>
            <a href='#' className='menu-link pe-0 pe-2'>
              Contact
            </a>
          </li>
          <li className='menu-item'>
            <a href='#' className='menu-link pe-0'>
              Purchase
            </a>
          </li>
        </ul> */}
        {/* end::Nav */}
      </div>
      {/* end::Container */}
    </div>
  )
}

export {Footer}
